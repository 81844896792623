.Logo {
    max-width: 80px;
  }
  @media (max-width: 800px) {
    .Logo {
      max-width: 80px;
    }
  }
  
  .Menu {
    width: 100%;
    height: 50px;
    z-index: 100;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 5%;
    padding-right: 5%;
  
    background: var(--black);
    border-bottom: 2px solid var(--primary);
  }
  body {
    --bodyPaddingTop: 50px;
    padding-top: var(--bodyPaddingTop);
  }
  @media (max-width: 800px) {
    .Menu {
      height: 40px;
      justify-content: center;
    }
    body {
      --bodyPaddingTop: 40px;
      padding-top: var(--bodyPaddingTop);
    }
  }
  
  .ButtonLink {
    color: var(--white);
    border: 1px solid var(--white);
    box-sizing: border-box;
    cursor: pointer;
    padding: 16px 24px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    transition: opacity .3s;
  }
  .ButtonLink:hover,
  .ButtonLink:focus {
    opacity: .5;
  }
  
  @media (max-width: 600px) {
    a.ButtonLink {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--primary);
      border-radius: 0;
      border: 0;
      text-align: center;
    }
  }